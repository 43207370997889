import { GAME_LIST, REGIST_GAME_LIST, PAST_GAME_LIST } from '../constants/ActionTypes';

const INITIAL_STATE = {
    gameList: [],
    registedGameList: [],
    pastGameList: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GAME_LIST:
            return {
                ...state,
                gameList: action.payload
            }
        case REGIST_GAME_LIST:
            return {
                ...state,
                registedGameList: action.payload
            };
        case PAST_GAME_LIST:
            return {
                ...state,
                pastGameList: action.payload
            };
        default:
            return state;
    }
};