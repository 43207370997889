import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GAME_LIST,
    REGIST_GAME_LIST,
    PAST_GAME_LIST
} from "../constants/ActionTypes";
import axios from '../util/Api';

export const getGameList = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/games',
        ).then(({ data }) => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: GAME_LIST, payload: data.games });

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: "Get game list request failed." });
            console.log("Error****:", error.message);
        });
    }
};

export const getRegistedGameList = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/registed-games').then(({ data }) => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: REGIST_GAME_LIST, payload: data.games });
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: "Get registered game list request failed." });
            console.log("Error****:", error.message);
        });
    }
}

export const getPastGameList = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/past-games').then(({ data }) => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: PAST_GAME_LIST, payload: data.games });
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: "Get past game list request failed." });
            console.log("Error****:", error.message);
        });
    }
}

export const playGame = (game) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.get('/past-games').then(({ data }) => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: PAST_GAME_LIST, payload: data.games });
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: "Get past game list request failed." });
            console.log("Error****:", error.message);
        });
    }
}