const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getHours()}:${date.getSeconds()}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function genIdArray(docs) {
    let gameIds = [];
    if(docs && docs.length) {
        for (let i = 0; i < docs.length; i++) {
            gameIds.push(docs[i].game._id);
        }
    }
    return gameIds;
}
