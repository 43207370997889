import React, { Component } from 'react';
import { Link, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Upcoming from './Upcoming';
import Registerations from './Registerations';
import Past from './Past';

class Matches extends Component {
    render() {
        console.log(" MATHCHES PROPS =>", this.props)
        const { pathname } = this.props.location; 
        return (
            <div className="bg-white">
                <div className="content content-full mt-6">
                    {/* sub navigation */}
                    <div className="bg-sidebar-dark p-3 rounded push">
                        {/* Toggle Navigation */}
                        <div className="d-lg-none">
                            {/* Class Toggle, functionality initialized in Helpers.coreToggleClass() */}
                            <button type="button" className="btn btn-block btn-dark d-flex justify-content-between align-items-center"
                                data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered-dark" data-class="d-none">
                                Menu - Hover Centered Dark
                                <i className="fa fa-bars" />
                            </button>
                        </div>
                        {/* END Toggle Navigation */}
                        {/* Navigation */}
                        <div id="horizontal-navigation-hover-centered-dark" className="d-none d-lg-block mt-2 mt-lg-0">
                            <ul className="nav-main nav-main-horizontal nav-main-hover nav-main-dark">
                                <li className="nav-main-heading">Showcase</li>
                                <li className="nav-main-item">
                                    <Link className={`nav-main-link${pathname === "/pr/matches/upcoming" ? " active" : "" }`} to="/pr/matches/upcoming">
                                        <i className="nav-main-link-icon fa fa-rocket" />
                                        <span className="nav-main-link-name">All Upcoming</span>
                                    </Link>
                                </li>
                                <li className="nav-main-item">
                                    <Link className={`nav-main-link${pathname === "/pr/matches/registerations" ? " active" : "" }`} to="/pr/matches/registerations">
                                        <i className="nav-main-link-icon fa fa-boxes" />
                                        <span className="nav-main-link-name">My Registerations</span>
                                    </Link>
                                </li>
                                <li className="nav-main-item">
                                    <Link className={`nav-main-link${pathname === "/pr/matches/past" ? " active" : "" }`} to="/pr/matches/past">
                                        <i className="nav-main-link-icon fa fa-unlink" />
                                        <span className="nav-main-link-name">Past Results</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* END Navigation */}
                    </div>
                    {/* sub content */}
                    <div className="block block-rounded block-bordered d-none d-lg-block">
                        <div className="block-content">
                            <Switch>
                                <Redirect exact path="/pr/matches/" to="/pr/matches/upcoming" />
                                <Route path="/pr/matches/upcoming" component={Upcoming} />
                                <Route path="/pr/matches/registerations" component={Registerations} />
                                <Route path="/pr/matches/past" component={Past} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ game }) => ({ game });
export default connect(mapStateToProps)(Matches);