export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

export const GAME_LIST = 'game_list';
export const REGIST_GAME_LIST = 'regist_game_list';
export const PAST_GAME_LIST = 'past_game_list';


