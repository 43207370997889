import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from '../util/Api';
import { getUser, getGameList, getRegistedGameList, playGame } from '../actions';
import { connect } from 'react-redux';
import { formatDate } from '../util';

class GameCard extends Component {
    state = {
        doc: null
    };
    componentDidMount() {
        if (this.props.isisRegistered) {
            axios.post('/registed-game-info', { gameId: this.props.game._id }).then(({ data }) => {
                console.log("registed-game-info api res ----> ", data);
                const { doc } = data;
                this.setState({ doc });
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isRegistered) {
            axios.post('/registed-game-info', { gameId: this.props.game._id }).then(({ data }) => {
                console.log("registed-game-info api res ----> ", data);
                const { doc } = data;
                this.setState({ doc });
            })
        }
    }
    onClickPlay = (game) => {
        if (game.server) {
            window.open(`${game.server}`, "_blank");
        } else {
            alert("Sever was not assigned !");
        }
    }
    onClickReview = (game) => {
        console.log("game  ---> ", game);
        axios.post('/review-result', { game }).then(({ data }) => {
            console.log("---- play game api res ---", data);
            this.props.getGameList();
            this.props.getRegistedGameList();
        });
    }
    onClickRegister = (game) => {
        axios.post('/register-game', { fee: game.fee, gameId: game._id }).then(({ data }) => {
            this.props.getUser();
            this.props.getGameList();
            this.props.getRegistedGameList();
        });
    }
    render() {
        const { game, isRegistered } = this.props;
        const { doc } = this.state;
        console.log(" Game Card state ==", this.state);
        if (game) {
            return (
                <div className="card mb-2">
                    <div className="card-top-image"
                        style={{ backgroundImage: `url(${game.image})` }}>
                    </div>
                    <div className="card-bottom-image"
                        style={{ backgroundImage: "url('/assets/media/photos/card-bottom-image.png')" }}>
                        <h5 className="font-w700 text-black px-3 py-2 my-0">Game: {game.name}</h5>
                        <div className="px-2">
                            <div className="d-flex mb-2">
                                <img className="ellipse-icon"
                                    src="/assets/media/photos/ellipse.png"
                                    alt=""
                                />
                                <div className="card-font">Start at: <span
                                    className="text-success ml-1">{formatDate(game.startAt)}</span>
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <img className="ellipse-icon"
                                    src="/assets/media/photos/ellipse.png"
                                    alt=""
                                />
                                <div className="card-font">Difficulty: <span
                                    className="text-success ml-1">{game.difficulty}</span></div>
                            </div>
                            <div className="d-flex mb-2">
                                <img className="ellipse-icon"
                                    src="/assets/media/photos/ellipse.png"
                                    alt=""
                                />
                                <div className="card-font">Status: <span
                                    className="text-success ml-1">{game.status}</span></div>
                            </div>
                            <div className="d-flex mb-2">
                                <img className="ellipse-icon"
                                    src="/assets/media/photos/ellipse.png"
                                    alt=""
                                />
                                <div className="card-font">Entry fee: <span
                                    className="text-danger ml-1">{game.fee}</span>
                                </div>
                                <img className="my-auto ml-2 coin-icon"
                                    src="/assets/media/photos/coin.png"
                                    alt=""
                                />
                            </div>
                            <div className="d-flex ml-2 pb-2">
                                <h6 className="coin-icon text-black font-w700 mb-0">Prize: <span
                                    className="text-success font-size-base font-w700 ml-1">{game.prize}</span>
                                </h6>
                                <img className="moneybag-icon ml-1 mr-2"
                                    src="/assets/media/photos/money-bag.png"
                                    alt=""
                                />
                                {
                                    game.status !== "open" ?
                                        <em className="font-size-sm text-danger m-auto">Finished</em>
                                        : (
                                            isRegistered ?
                                                (
                                                    doc && doc.process === "registeration" ?
                                                        <>
                                                            <button className="btn btn-rounded btn-danger btn-card ml-auto mr-1" onClick={() => this.onClickPlay(game)}>Play Game</button>
                                                            <button type="button" className="btn btn-rounded btn-success btn-card ml-auto" onClick={() => this.onClickReview(game)}>Review Result</button>
                                                        </>
                                                        : doc && doc.process === "end" && <em className="font-size-sm text-danger m-auto">Reviewing result</em>
                                                )
                                                : <button className="btn btn-rounded btn-primary btn-card ml-auto" onClick={() => this.onClickRegister(game)}>Register</button>
                                        )
                                }
                                {/* <a href={`steam://connect/${game.server}`} className="btn btn-rounded btn-danger btn-card ml-auto" target="_blank" rel="noopener noreferrer">Play Game</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
}

GameCard.propTypes = {
    game: PropTypes.object
};

const mapDispatchToProps = { getUser, getGameList, getRegistedGameList, playGame };
export default connect(() => ({}), mapDispatchToProps)(GameCard);

