import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from '../util/Api';

class Stats extends Component {
    state = {
        isLoading: false,
        wins: null,
        loss: null,
        prizedCoins: null
    };
    componentDidMount() {
        axios.get('/stats').then(({ data }) => {
            console.error(" Stats api res --->", data);
            this.setState({
                isLoading: false,
                wins: data.wins.length,
                loss: data.loss.length,
                prizedCoins: data.prizedCoins
            })
        });
    }
    render() {
        console.log("STATs PROPS ===========", this.props);
        const { authUser } = this.props.auth;
        const { gameList, registedGameList } = this.props.game;
        const { isLoading, wins, loss, prizedCoins } = this.state;
        return (
            <div className="bg-dark bg-image" style={{ backgroundImage: "url('/assets/media/photos/brand.jpg')" }}>
                <div className="bg-black-75 d-flex" style={{ height: "100vh" }}>
                    <div className="bg-white border-rounded border-primary w-100 mw-60 m-auto">
                        <div className="d-flex bg-primary headline-section">
                            <div className="px-5 py-3 bg-primary-hover headline-section-logo">
                                <h1 className="text-white font-w700 mb-0">Stats</h1>
                            </div>
                        </div>
                        <div className="d-flex align-items-center p-4">
                            <img className="img-avatar mr-2" src={`${authUser && authUser.image}`} alt="" />
                            <span className="font-size-lg font-w700 mb-0">{authUser && authUser.username}</span>
                        </div>
                        <div className="row no-gutters p-3">
                            {
                                isLoading ?
                                    <div className="d-flex w-100 justify-content-center mb-5">
                                        <span className="font-size-lg">Loading ...</span>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/check.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Wins</p>
                                                <span className="text-black font-size-lg font-w700">{wins}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/target.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Kills</p>
                                                <span className="text-black font-size-lg font-w700">{wins}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/coin.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Coins prized</p>
                                                <span className="text-black font-size-lg font-w700">{prizedCoins}</span>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/times.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Loss</p>
                                                <span className="text-black font-size-lg font-w700">{loss}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/clock.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Turn Played</p>
                                                <span className="text-black font-size-lg font-w700">{wins + loss}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 d-flex align-items-center px-3 mb-4">
                                            <img className="img-avatar img-avatar48" src="/assets/media/photos/trophy.png" alt="" />
                                            <div className="px-3">
                                                <p className="text-primary font-size-sm font-w700 mb-0">Sum Of Wins</p>
                                                <span className="text-black font-size-lg font-w700">{wins}</span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, game }) => ({ auth, game });
export default connect(mapStateToProps)(Stats);