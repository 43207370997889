import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formatDate, genIdArray } from '../../util';
import { getPastGameList } from '../../actions';

class Past extends Component {

    componentDidMount() {
        this.props.getPastGameList();
    }
    render() {
        const { pastGameList } = this.props.game;
        return (
            <React.Fragment>
                <table className="table table-striped table-borderless table-vcenter">
                    <tbody>
                        {
                            pastGameList.length ?
                                pastGameList.map(doc => {
                                    console.log(" my PAST game ", doc);
                                    const { game, isWinner } = doc;
                                    return (
                                        <tr key={game._id}>
                                            <td className="text-center" style={{ width: 65 }}>
                                                <img src="/assets/media/cs-icon.png" alt="" />
                                            </td>
                                            <td>
                                                <div className="font-size-h5 font-w600 text-primary">{game.name}</div>
                                                <div className="text-muted mt-2 mb-1">
                                                    {
                                                        isWinner === true && <span className="text-muted">WINNER</span>
                                                    }
                                                    {
                                                        isWinner === false && <span className="text-muted">LOSER</span>
                                                    }
                                                    {
                                                        isWinner === null && <span className="text-muted">DRAWN</span>
                                                    }
                                                </div>
                                                <div className="font-size-sm text-muted">
                                                    <span className="badge badge-primary font-w600 mx-2">Fee</span>{game.fee}
                                                    <span className="badge badge-warning font-w600 mx-2">Prize</span>{game.prize}
                                                </div>
                                            </td>

                                            <td className="d-none d-md-table-cell text-center">
                                                {
                                                    isWinner ?
                                                        <><span className="badge badge-warning font-w600 mr-1">Prize</span> + {game.prize}</>
                                                        : <><span className="badge badge-primary font-w600 mr-1">Fee</span> - {game.fee}</>
                                                }
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                <span className="font-size-sm text-muted">
                                                    Started at <em className="text-primary mx-1">{formatDate(game.startAt)}</em>, finished
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr className="d-none d-md-table-cell">
                                    <td className="d-none d-md-table-cell">
                                        <p className="text-center">No past matches..</p>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ game }) => ({ game });
export default connect(mapStateToProps, { getPastGameList })(Past);