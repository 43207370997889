import React, { Component } from 'react';
import GameCard from '../components/GameCard';
import { connect } from 'react-redux';
import { getUser, getGameList, getRegistedGameList } from '../actions';
import { genIdArray } from '../util';

class Games extends Component {
    _isMounted = false;


    render() {
        console.log(" GAME LIST PROPS --------------------------", this.props);
        const { gameList, registedGameList } = this.props.game;
        const registedGameIdList = genIdArray(registedGameList);
        return (
            <div className="bg-white">
                <div className="content content-full mt-6">
                    <div className="block-content">
                        <div className="row py-3 justify-content-center">
                            <h3 className="font-w700 text-black text-uppercase">
                                Play games
                                </h3>
                        </div>
                        {/* <!-- first row --> */}
                        <div className="row mx-4 mb-4 justify-content-around">
                            {
                                gameList && registedGameIdList && gameList.length ?
                                    gameList.map((game, index) => {
                                        const isRegistered = registedGameIdList.includes(game._id);
                                        return <GameCard key={index} game={game} isRegistered={isRegistered} />
                                    })
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ game }) => ({ game });
export default connect(mapStateToProps, { getUser, getGameList, getRegistedGameList })(Games);