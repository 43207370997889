import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate, genIdArray } from '../../util';
import axios from '../../util/Api';
import { getUser, getGameList, getRegistedGameList } from '../../actions';

class Upcoming extends Component {
    state = {
        isLoading: true,
        gameList: []
    }
    componentDidMount() {
        this.props.getGameList();
    }
    onClickRegister = (game) => {
        axios.post('/register-game', { fee: game.fee, gameId: game._id }).then(() => {
            this.props.getUser();
            this.props.getGameList();
            this.props.getRegistedGameList();
        });
    }
    render() {
        console.log("UPCOMING PROPS =>", this.props);
        const { gameList, registedGameList } = this.props.game;
        const registedGameIdList = genIdArray(registedGameList);
        let upcomingList = [];
        if (gameList && gameList.length) {
            upcomingList = gameList.filter(game => {
                const startDate = new Date(game.startAt);
                return startDate.getTime() >= Date.now() && game.status === "open";
            });
        }
        return (
            <React.Fragment>
                <table className="table table-striped table-hover table-borderless table-vcenter bg-white">
                    <tbody>
                        {
                            upcomingList.length ?
                                upcomingList.map(game => {
                                    const startDate = new Date(game.startAt);
                                    const isRegisted = registedGameIdList.includes(game._id);
                                    return (
                                        <tr key={game._id}>
                                            <td>
                                                <div className="d-sm-flex">
                                                    <div className="ml-sm-2 mr-sm-4 py-3">
                                                        <div className="item item-rounded bg-body-dark text-dark font-size-h2 mb-2 mx-auto">
                                                            <img src="/assets/media/cs-icon.png" alt="" />
                                                        </div>
                                                        {
                                                            !isRegisted &&
                                                            <button className="btn btn-sm btn-success btn-block" onClick={() => this.onClickRegister(game)}>Register</button>
                                                        }
                                                    </div>
                                                    <div className="py-3">
                                                        <div className="link-fx h4 mb-1 d-inline-block text-primary">{game.name}</div>
                                                        <div className="font-size-sm font-w600 text-muted mb-2">
                                                            Start at <em>{`${formatDate(startDate)}`}</em>
                                                        </div>
                                                        <p className="text-muted mb-2">
                                                            {game.description ? game.description : "No description.."}
                                                        </p>
                                                        <div>
                                                            <span className="badge badge-primary font-w600">Status</span><span className="font-w400 mx-2">{game.status}</span>
                                                            <span className="badge badge-warning font-w600">Fee</span><span className="font-w400 mx-2">{game.fee}</span>
                                                            <span className="badge badge-success font-w600">Prize</span><span className="font-w400 mx-2">{game.prize}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        isRegisted ?
                                                            <div className="py-3 m-auto">
                                                                <em className="font-size-sm text-warning">
                                                                    Registered
                                                            </em>
                                                            </div>
                                                            :
                                                            <div className="py-3 m-auto">
                                                                <em className="font-size-sm text-primary">
                                                                    Register to play
                                                                </em>
                                                            </div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr className="d-none d-md-table-cell">
                                    <td className="d-none d-md-table-cell">
                                        <p className="text-center">No upcoming matches..</p>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}
const mapStateToProps = ({ game }) => ({ game });
export default connect(mapStateToProps, { getUser, getGameList, getRegistedGameList })(Upcoming);