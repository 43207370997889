import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatDate, genIdArray } from '../../util';
import axios from '../../util/Api';
import { getUser, getGameList, getRegistedGameList } from '../../actions';

class Registerations extends Component {
    componentDidMount() {
        this.props.getRegistedGameList();
    }
    onClickPlay = (game) => {
        if (game.server) {
            window.open(`${game.server}`, "_blank")
        } else {
            alert("Sever was not assigned !");
        }
    }
    onClickReview = (game) => {
        console.log("game  ---> ", game);
        axios.post('/review-result', { game }).then(({ data }) => {
            console.log("---- play game api res ---", data);
            this.props.getGameList();
            this.props.getRegistedGameList();
        });
    }
    onClickCancel = (game) => {
        axios.post('/cancel-game', { fee: game.fee, gameId: game._id }).then(() => {
            this.props.getUser();
            this.props.getRegistedGameList();
        });
    }
    render() {
        console.log(" REGISTERATION PROPS ==>", this.props);
        const { registedGameList } = this.props.game;
        return (
            <React.Fragment>
                <table className="table table-striped table-hover table-borderless table-vcenter bg-white">
                    <tbody>
                        {
                            registedGameList.length ?
                                registedGameList.map((doc, index) => {
                                    const game = doc.game;
                                    const isCancelDisabled = game.status !== 'open' ||
                                        // new Date(game.startAt).getTime() < Date.now() ||
                                        doc.process === "end";
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-sm-flex">
                                                    <div className="ml-sm-2 mr-sm-4 py-3">
                                                        <div className="item item-rounded bg-body-dark text-dark font-size-h2 mb-2 mx-auto">
                                                            <img src="/assets/media/cs-icon.png" alt="" />
                                                        </div>
                                                        {
                                                            !isCancelDisabled &&
                                                            <button className="btn btn-sm btn-primary btn-block"
                                                                onClick={() => this.onClickCancel(game)}>Cancel</button>
                                                        }
                                                    </div>
                                                    <div className="py-3">
                                                        <div className="link-fx h4 mb-1 d-inline-block text-primary">{game.name}</div>
                                                        <div className="font-size-sm font-w600 text-muted mb-2">
                                                            Start at <em>{`${formatDate(game.startAt)}`}</em>
                                                        </div>
                                                        <p className="text-muted mb-2">
                                                            {game.description ? `${game.description}` : "No description..."}
                                                        </p>
                                                        <div>
                                                            <span className="badge badge-primary font-w600">Status</span><span className="font-w400 mx-2">{game.status}</span>
                                                            <span className="badge badge-warning font-w600">Fee</span><span className="font-w400 mx-2">{game.fee}</span>
                                                            <span className="badge badge-success font-w600">Prize</span><span className="font-w400 mx-2">{game.prize}</span>
                                                        </div>
                                                    </div>
                                                    <div className="py-3 m-auto">

                                                        {
                                                            doc.process === "registeration" ?
                                                                <em className="font-size-sm text-primary">
                                                                    Registeration approved, ready to play.
                                                                    </em>
                                                                : null
                                                        }
                                                        {
                                                            doc.process === "end" ?
                                                                <em className="font-size-sm text-warning">
                                                                    Result is under review
                                                                    </em>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="py-3 m-auto">
                                                        {
                                                            doc.process === "registeration" &&
                                                            <>
                                                                <button type="button" className="btn btn-hero-danger mr-1" onClick={() => this.onClickPlay(game)}>Play Game</button>
                                                                <button type="button" className="btn btn-hero-success" onClick={() => this.onClickReview(game)}>Review Result</button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr className="d-none d-md-table-cell">
                                    <td className="d-none d-md-table-cell">
                                        <p className="text-center">No upcoming matches..</p>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ game }) => ({ game });
export default connect(mapStateToProps, { getUser, getGameList, getRegistedGameList })(Registerations);